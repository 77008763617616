import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";

function TableForm1() {
  return (
    <div className="container mt-5">
      <div className="card">
        <div className="card-header bg-light">
          <div className="d-flex justify-content-between align-items-center">
            <h5>Weekly Timesheet</h5>
            <a href="#" className="btn btn-link">Copy Previous Week Data</a>
          </div>
          <p className="text-danger mb-0">Note: Enter Time in the following format: "hh:mm"</p>
        </div>

        <div className="card-body">
          <form>
            <div className="row mb-3">
              <div className="col-md-3">
                <label htmlFor="empId" className="form-label">Emp ID</label>
                <input type="text" className="form-control" id="empId" />
              </div>
              <div className="col-md-3">
                <label htmlFor="designation" className="form-label">Designation</label>
                <input type="text" className="form-control" id="designation" value="CIVIL DESIGNER" readOnly />
              </div>
              <div className="col-md-3">
                <label htmlFor="month" className="form-label">Month</label>
                <select className="form-select" id="month">
                  <option value="May">May</option>
                  <option value="June">June</option>
                  <option value="July">July</option>
                  {/* Add more months as needed */}
                </select>
              </div>
              <div className="col-md-3">
                <label htmlFor="weekNo" className="form-label">Week No</label>
                <input type="text" className="form-control" id="weekNo" value="19 (05-05-2024 to 11-05-2024)" readOnly />
              </div>
            </div>

            <table className="table table-bordered">
              <thead className="table-light">
                <tr>
                  <th>Job Code</th>
                  <th>Drawing Number</th>
                  <th>Description</th>
                  <th colSpan="7" className="text-center">Working Hours</th>
                  <th>Comments</th>
                </tr>
                <tr>
                  <th colSpan="3"></th>
                  <th>Sunday</th>
                  <th>Monday</th>
                  <th>Tuesday</th>
                  <th>Wednesday</th>
                  <th>Thursday</th>
                  <th>Friday</th>
                  <th>Saturday</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>J7483</td>
                  <td>STP-2109-92DD...</td>
                  <td>132KV CABLE TRENCH/PULL PIT LAYOUT AND RC DETAILS</td>
                  <td>08:33</td>
                  <td>08:18</td>
                  <td>08:21</td>
                  <td>00:00</td>
                  <td>00:00</td>
                  <td>00:00</td>
                  <td>00:00</td>
                  <td>COMPLETED_(REV-00)</td>
                </tr>
                <tr>
                  <td>J7522</td>
                  <td>55-2023-SAS-C...</td>
                  <td>33KV SWGR & CONTROL BUILDING: GROUND FLOOR BEAM...</td>
                  <td>01:05</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>03:00</td>
                  <td></td>
                  <td></td>
                  <td>COMPLETED_(REV-A)</td>
                </tr>
                <tr>
                  <td>J7523</td>
                  <td>55-2023-MUDC...</td>
                  <td>GENERAL NOTES AND LEGEND FOR CIVIL WORKS</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>01:21</td>
                  <td>COMPLETED_(REV-A)</td>
                </tr>
                {/* Add more rows as needed */}
              </tbody>
            </table>

            <div className="row mt-3">
              <div className="col-md-4">
                <label>Total Required Work Hours:</label>
                <input type="text" className="form-control" value="32:00" readOnly />
              </div>
              <div className="col-md-4">
                <label>Total Worked Hours:</label>
                <input type="text" className="form-control" value="33:17" readOnly />
              </div>
            </div>

            <div className="d-flex justify-content-between mt-4">
              <button type="button" className="btn btn-secondary">Time Sheet in Progress</button>
              <button type="submit" className="btn btn-primary">Save</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default TableForm1;

import React, { useState, useEffect } from "react";
import { Menu } from "../../../header/Header";
import axios from "axios";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";


import { FaFilePdf } from "react-icons/fa";
import { FaFileImage } from "react-icons/fa6";
import { TbFileTypeDocx } from "react-icons/tb";
import { BsFiletypeXlsx } from "react-icons/bs";

import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import SendIcon from "@mui/icons-material/Send";

import SearchIcon from "@mui/icons-material/Search";
import Loader from "../../../Loader";
import { FaEye } from "react-icons/fa6";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const RecruitmentPostDocuments = ({ mode, recid }) => {
  const api_url = process.env.REACT_APP_API_URL;
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const [documentList, setDocumentList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [file, setFile] = useState("");

  useEffect(() => {
    if (!token) {
      navigate("/");
    }

    handleFetchList(recid);
  }, []);

  const handleFetchList = async(id)=>{
    try {
        setLoading(true);
        await axios
          .get(
            `${api_url}/recruitment/recruitment-master/retrievedocuments/${id}`
          )
          .then((res) => {
            //  console.log("res",res);
            setDocumentList(res.data.files);
            setLoading(false);
          });
      } catch (err) {
        toast.error( "Error retrieving documents... Please contact administrator  ", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          theme: "light",
          className:"custom-toast"
        });
        console.log(err);
      } 
  }

  const handleUpload = async (id, file) => {
    try {
        setLoading(true);
      const formData = new FormData();
      formData.append("Recruitment-Document", file);
      const response = await axios.post(
        `${api_url}/recruitment/recruitment-master/uploaddocument/${id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if(response.data.success===true){
        handleFetchList(recid);
        setLoading(false);
        toast.success( "Document uploaded successfully...!", {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            theme: "light",
            className:"custom-toast"
          });

      }
      else{
        toast.error( "Failed to upload Document", {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            theme: "light",
            className:"custom-toast"
          });
      }
    } catch (error) {
      console.error("Error uploading profile picture:", error);
    }
  };

  const HandleFileChange = async (event) => {
    const allowedTypes = [
      "image/jpeg",
      "image/png",
      "image/jpg",
      "application/pdf",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    ];

    const file = event.target.files[0];
    if (file) {
      if (allowedTypes.includes(file.type)) {
        setFile(file);
      } else {
        alert("Please upload a valid file type: JPG, JPEG, PNG, PDF, or DOCX.");
        event.target.value = "";
      }
    } else {
      alert("Please upload a file.");
    }
  };

  const handleView = async (filename) => {
    try {
    const url = `https://atlas-om.app:30000/RecruitmentDocuments/${recid}/${filename}`
      window.open(url, "_blank");
    } catch (error) {
      toast.error( "Failed to retrieve document. Please contact administrator", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        theme: "light",
        className:"custom-toast"
      });
    
    }
  };

  const handleDelete = async (id,filename) => {
    console.log("Deleting file with id:", id, "and filename:", filename);

    await axios
      .delete(`${api_url}/recruitment/recruitment-master/deletefile/${id}/${filename}`)
      // .delete(`${api_url}/recruitment/recruitment-master/deletefile/18/20240924_111601.jpg`)
      .then((res) => {
        console.log(res);
        if(res.data.success===true){
          handleFetchList(recid);
          toast.success("Document Deleted successfully!", {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            theme: "light",
          });
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("Error in Deleting Document!", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          theme: "light",
        });
      });
  };
  return (
    <>
    {loading ? (
        <Loader />
      ) : (
        <div className="container">
          <div className="p-1 d-flex justify-content-end">
            <Button
              type="button"
              variant="contained"
              color="primary"
              endIcon={<SendIcon />}
              data-bs-toggle="modal"
              data-bs-target="#documentslistmodal"
            >
              Send Mail
            </Button>
          </div>

          {/* Modal for uploading documents */}
          <div
            className="modal fade"
            id="documentslistmodal"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabIndex="-1"
            aria-labelledby="screeningmodal"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-header">
                  <h1 className="modal-title fs-5">Send Document</h1>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body">
                <div className="container-fluid">
                    <div className="row">
                      <div className="col">
                        <div className="row mb-3">
                          <div className="col-2">
                            <label className="form-label" htmlFor="to">
                              TO:
                            </label>
                          </div>
                          <div className="col-10">
                            <input
                              type="text"
                              className="form-input-text"
                              name="to"
                              // value={offerDetails.PersonalEmailID || ""}
                              id="to"
                              disabled
                            />
                          </div>
                        </div>

                        <div className="row mb-3">
                          <div className="col-2">
                            <label className="form-label" htmlFor="cc">
                              CC
                            </label>
                          </div>
                          <div className="col-10">
                            <input
                              type="text"
                              className="form-input-text"
                              name="cc"
                              value={"rahima@atlas-om.com"}
                              id="cc"
                              disabled
                            />
                          </div>
                        </div>
                        <div className="row mb-3">
                          <div className="col-2">
                            <label className="form-label" htmlFor="to">
                              Subject
                            </label>
                          </div>
                          <div className="col-10">
                            <input
                              type="text"
                              className="form-input-text"
                              name="to"
                              // value={offerDetails.PersonalEmailID}
                              value={"Draft Offer Letter - Atlas International"}
                              id="to"
                              disabled
                            />
                          </div>
                        </div>

                        <div className="row mb-3">
                          <div className="col-2">
                            <label className="form-label" htmlFor="to">
                              Message:
                            </label>
                          </div>
                          <div className="col-10">
                            <div
                              className="form-input-textarea circular-message-textarea"
                              contentEditable={true}
                              // dangerouslySetInnerHTML={{ __html: htmlContent }} 
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    data-bs-dismiss="modal"
                    onClick={() => handleUpload(recid, file)}
                  >
                    Upload
                  </Button>
                </div>
              </div>
            </div>
          </div>

          {/* Document List */}
          <div className="table-view-container-wrapper">
            <div className="p-2 d-flex fw-bold shadow justify-content-center blockquote">
              <div>Post Recruitment Document sent List</div>
            </div>
            <div className="document-list">
              {documentList.length > 0 ? (
                documentList.map((filename, index) => (
                  <div className="ms-5">
                    <div
                      key={index}
                      className="document-item d-flex align-items-center mb-4"
                    >
                         {/* {getFileIcon(filename)}  */}
                      {/* <FaFilePdf className="pdf-icon me-2" /> */}
                      <span>
                        {index + 1}. 
                      </span>
                      <span className="ms-2">{filename}</span>
                      <FaEye
                        className="view-employee-document ms-5"  style={{ color: '#0d6efd' }}
                        onClick={() => handleView(filename)}
                      />
                      <DeleteIcon className="ms-3"  style={{ color: '#d32f2f',cursor: "pointer" }} onClick={() => handleDelete(recid,filename)}/>
                      
                    </div>
                  </div>
                ))
              ) : (
                <div className="d-flex justify-content-center mt-2">No documents available.</div>
              )}
            </div>
          </div>
        </div>
      )}
  </>
  );
};

export default RecruitmentPostDocuments;

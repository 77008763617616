import { Route, Routes, useLocation } from "react-router-dom";
import { useState } from "react";
import Header from "./components/header/Header";
import Sidebar from "./components/sidebar/Sidebar";
import Login from "./components/login/Login";
import HRDashboard from "./components/HR/HRDashboard";
import EmployeeManagement from "./components/HR/Employees/EmployeeManagement";
import EmployeeForm from "./components/HR/Employees/EmployeeForm";
import CircularForm from "./components/HR/Circulars/CircularForm";
import "./App.css";
import Circulars from "./components/HR/Circulars/Circulars";
import Dependent from "./components/HR/Dependent/Dependent";
import PayrollManagement from "./components/HR/Payroll/PayrollManagement";
import Attendance from "./components/HR/Payroll/Attendance";
import Earning from "./components/HR/Payroll/Earning";
import Deduction from "./components/HR/Payroll/Deduction";
import FinalSheet from "./components/HR/Payroll/FinalSheet";
import ApplicantDatabase from "./components/HR/Recruitment/ApplicantDatabase";
import ApplicantDatabaseForm from "./components/HR/Recruitment/ApplicantDatabaseForm";
import EmployeeOverview from "./components/HR/Employees/EmployeeOverview";
import HRDocumentsForm from "./components/HR/HRDocuments/HRDocumentsForm";
import HRDocuments from "./components/HR/HRDocuments/HRDocuments";

import EmployeeDocumentsList from "./components/HR/Employees/EmployeeDocumentsList";
import EmpDocumentForm from "./components/HR/Employees/EmpDocumentForm";

import HRForms from "./components/HR/HRUtilities/HRForms";
import HRFormsGrid from "./components/HR/HRUtilities/HRFormsGrid";
import ChangePasswordForm from "./components/user/ChangePasswordForm";
import TimeManagement from "./components/commonModules/TimeManagement/TimeManagement";

import RequestDashboard from "./components/commonModules/requests/RequestDashboard";
import LeaveRequestList from "./components/commonModules/requests/LeaveRequest/LeaveRequestList";
import LeaveRequestForm from "./components/commonModules/requests/LeaveRequest/LeaveRequestForm";
import LeaveRequestForm1 from "./components/commonModules/requests/LeaveRequest/LeaveRequestForm1";
import LeaveRequestForm2 from "./components/commonModules/requests/LeaveRequest/LeaveRequestForm2";
import LeaveApprovalList from "./components/approvals/LeaveApprovals/LeaveApprovalList";
import ITComplaintRequestForm from "./components/commonModules/requests/IT Request/ITComplaintRequestForm";
import ITComplaintRequestList from "./components/commonModules/requests/IT Request/ITComplaintRequestList";

import RecruitmentPlanList from "./components/HR/RecruitmentPlan/RecruitmentPlanList";
import RecruitmentPlanForm from "./components/HR/RecruitmentPlan/RecruitmentPlanForm";

import RecruitmentMasterForm from "./components/HR/Recruitment/RecruitmentMaster/RecruitmentMasterForm";
import RecruitmentMasterList from "./components/HR/Recruitment/RecruitmentMaster/RecruitmentMasterList";

import ScreeningGridList from "./components/HR/Recruitment/RecruitmentMaster/ScreeningGridList";

import RecruitmentMasterStepper from "./components/HR/Recruitment/RecruitmentMaster/RecruitmentMasterStepper";

import BaseLocationApprovalList from "./components/user/BaseLocationApproval/BaseLocationApprovalList";

import ApprovalDashboard from "./components/approvals/ApprovalDashboard";

import ShiftOverview from "./components/user/ShiftOverview";

import TimesheetList from "./components/commonModules/timesheet/TimesheetList";
import TimesheetForm from "./components/commonModules/timesheet/TimesheetForm";
import TableForm from "./components/commonModules/timesheet/TableForm";
import TableForm1 from "./components/commonModules/timesheet/TableForm1";

import TestFile from "./components/commonModules/TimeManagement/TestFile";

// import RecruitmentOfferLetter from "./components/HR/Recruitment/RecruitmentMaster/RecruitmentOfferLetter";



function App() {
  const [sidebarVisible, setSidebarVisible] = useState(true);
  const [reportingManager,setReportingManager] = useState(false);
  const location = useLocation();
  

  const toggleSidebar = () => {
    setSidebarVisible(!sidebarVisible);
  };

  const toggleSidebarFalse = () =>{
    setSidebarVisible(false);
  }

  // const toggleReportingManager = () =>{
  //   setReportingManager(true);
  // }
  const toggleReportingManager = (value) => {
    setReportingManager(value);
  }

  

  const loginpath = location.pathname === "/";

  // console.log("reporting manager",reportingManager)

  
  return (
    <>
    <div className={`App ${!sidebarVisible ? "sidebar-hidden" : ""} ${loginpath ? 'login-page' : ''}`}>
      {!loginpath && (
        <>
          <Header onToggle={toggleSidebar} sidebarVisible={sidebarVisible} reportingManager={reportingManager} onToggleReportingManager={setReportingManager} />
          <Sidebar isVisible={sidebarVisible} onClose={toggleSidebar} onToggle={toggleSidebarFalse}/>
        </>
      )}
      <div className="content">
        <Routes>
          <Route exact path="/hrdash" element={<HRDashboard  onToggle={toggleSidebarFalse}/>} />
          <Route exact path="/" element={<Login onToggle={toggleReportingManager}/>} />
          {/* Employee Management */}
          <Route exact path="/empmgmt" element={<EmployeeManagement />} />
          <Route exact path="/empoverview" element={<EmployeeOverview />} />
          <Route exact path="/empform" element={<EmployeeForm />} />
          <Route exact path="/dependent" element={<Dependent/>}/>
          {/* Circulars */}
          <Route exact path="/circulars" element={<Circulars />} />
          <Route exact path="/cir-form" element={<CircularForm />} />
           {/* Payroll */}
          <Route exact path="/payroll" element={<PayrollManagement/>}/>
          <Route exact path="/attendance" element={<Attendance/>}/>
          <Route exact path="/earning" element={<Earning/>}/>
          <Route exact path="/deduction" element={<Deduction/>}/>
          <Route exact path="/finalsheet" element={<FinalSheet/>}/>
          {/* Applicant Database */}
          <Route exact path="/cvdb" element={<ApplicantDatabase/>}/>
          <Route exact path="/cvform" element={<ApplicantDatabaseForm/>}/>
          {/* HR Documents */}
          <Route exact path="/hrdocs" element={<HRDocuments/>}/>
          <Route exact path="/hrdocform" element={<HRDocumentsForm/>} />
          {/* Employee Documents */}
          <Route exact path="/empdocform" element={<EmpDocumentForm/>} />
          <Route exact path="/empdocs" element={<EmployeeDocumentsList/>} />
          {/* HR Forms */}
          <Route exact path="/hrforms" element={<HRForms/>} />
          <Route exact path="/hrformsmgmt" element={<HRFormsGrid/>} />
      {/* User Functions ----   Register Forms */}
      {/* Forgot Password */}
          <Route exact path="/user/change-password-form" element = {<ChangePasswordForm/>} />
          
          
          <Route exact path="/punch/baselocation/approvallist" element = {<BaseLocationApprovalList/>} /> 
          <Route exact path="/requestdashboard" element = {<RequestDashboard/>} />
          {/* Leave Request */}
          <Route exact path="/requests/leave-request-list" element = {<LeaveRequestList/>} />
          <Route exact path="/requests/leave-request-form" element = {<LeaveRequestForm2/>} />
          {/* Leave Approvals */}
          <Route exact path="/requests/leave-approvals-list" element = {<LeaveApprovalList/>} />
          {/* IT Complaint Request */}
          <Route exact path="/requests/it-complaint-request-form" element = {<ITComplaintRequestForm/>} />
          <Route exact path="/requests/it-complaint-list" element = {<ITComplaintRequestList/>} />
          {/* Base Location */}
          <Route exact path="/user/userpunch" element = {<TimeManagement/>} />
          {/* Recruitment Planning */}
          <Route exact path="/hr/recruitment-plan-list" element = {<RecruitmentPlanList/>} />
          <Route exact path="/hr/recruitment-plan-form" element = {<RecruitmentPlanForm/>} />
          {/* Recruitment Master */}
          <Route exact path="/hr/recruitment-master-list" element = {<RecruitmentMasterList/>} />
          <Route exact path="/hr/recruitment-master-form" element = {<RecruitmentMasterForm/>} />
          <Route exact path="/screening-grid-list" element = {<ScreeningGridList/>} />
          <Route exact path="/hr/recruitment-master/stepper" element = {<RecruitmentMasterStepper/>} /> 

          <Route exact path="/approval-dash" element = {<ApprovalDashboard onToggle={toggleSidebarFalse}/> } /> 

          <Route exact path="/shiftoverview" element = {<ShiftOverview />} /> 

          {/* Timesheet */}
          <Route exact path="/aiec/timesheetform" element = {<TimesheetForm />} /> 
          <Route exact path="/aiec/timesheetlist" element = {<TimesheetList />} /> 
          <Route exact path="/aiec/tableform" element = {<TableForm />} /> 
          <Route exact path="/aiec/tableform1" element = {<TableForm1 />} /> 
          
          <Route exact path="/test/file" element = {<TestFile />} /> 
          
          
        </Routes>
      </div>
    </div>
    </>
  );
}

export default App;
import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import { Menu } from "../../header/Header";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import Loader from "../../Loader";

import { FaEye } from "react-icons/fa6";

// import "./EmployeeManagement.css";

const TimesheetList = () => {
  const api_url = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();
  const userData = JSON.parse(sessionStorage.getItem("CurrentUserData"));
  const token = localStorage.getItem("token");

  if (!token) {
    navigate("/");
  }
  if (userData.AtlasEmpID !== "AEC002" && userData.AtlasEmpID !== "AEC057" && userData.AtlasEmpID !== "AEC052") {
    navigate("/user/userpunch");
  }
  const [timesheetList, setTimesheetList] = useState([]);
  const [loading, setLoading] = useState(true);

 
  const labels = [
    "S.No",
    "Date",
    "TypeOfDay",
    "AllCutOffHours",
    "OmaniCutOffHrs",
    "IslamCutOffHrs",
    "ExpatCutOffHrs",
    "Remarks",
    ""
  ];


  useEffect(() => {
    const fetchData = async () => {
      setLoading(true); // Start loading
      try {
        const res = await axios.get(`${api_url}/timesheet/get-timesheet-list`);
        const list = res.data.data;
        console.log("list of response",list)
        setTimesheetList(list);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(true);
      } 
    };

    fetchData();
  }, []);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className="container-fluid">
          <div className="button-wrapper">
            <div>
              <Menu link="/user/userpunch" />
            </div>
            <div>
              <Link to="/aiec/timesheetform" state={{ mode: "add" }}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  endIcon={<AddIcon />}
                >
                  Add Details
                </Button>
              </Link>
            </div>
          </div>
          {/* </div> */}
          <div className="table-view-container-wrapper">
            <div className="p-2 d-flex fw-bold shadow justify-content-center blockquote">
              Timesheet List
            </div>
            <table className="table table-hover table-responsive">
              <thead>
                <tr>
                  {labels.map((item, index) => (
                    <th
                      scope="col"
                      key={index}
                      className="table-header-font-size"
                    >
                      {item}{" "}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="table-body-font-size">
                {timesheetList.map((item, index) => {
                  const sno = index + 1;
                  return (
                    <tr
                      className="py-0"
                      key={index}
                      onClick={() => {
                        navigate("/aiec/timesheetform", {
                          state: { mode: "edit", DateID: item.DateID },
                        });
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      <td className="py-0">{sno}</td>
                      <td className="py-0">{item.Date}</td>
                      <td className="py-0">
                        {item.TypeOfDay}
                      </td>
                      <td className="py-0">{item.AllCutOffHours}</td>
                      <td className="py-0">{item.OmaniCutOffHrs}</td>
                      <td className="py-0">{item.IslamCutOffHrs}</td>
                      <td className="py-0">{item.ExpatCutOffHrs}</td>
                      <td className="py-0">{item.Remarks}</td>
                      <td className="py-0">
                        <Link
                          to="/aiec/timesheetform"
                          state={{ mode: "edit", DateID: item.DateID }}
                        >
                          <FaEye />
                        </Link>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </>
  );
};

export default TimesheetList;

import React, { useState, useEffect } from "react";
import axios from "axios";
import SendIcon from "@mui/icons-material/Send";
import Loader from "../../Loader";
import { Button } from "@mui/material";
import "./TimeManagement.css";
import { fetchCurrentDateTime, formatDateToMySQL } from "../CommonFunctions";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const TestFile = () => {
  const [loading, setLoading] = useState(true);
  const [baseLocation, setBaseLocation] = useState(false);
  const [punchList, setPunchList] = useState([]);
  const userData = JSON.parse(sessionStorage.getItem("CurrentUserData"));
  const [location, setLocation] = useState(null);
  const [mapSrc, setMapSrc] = useState("");
  const [siteVisit, setSiteVisit] = useState(false);

  const AtlasEmpID = userData.AtlasEmpID;
  const api_url = process.env.REACT_APP_API_URL;

  const getCurrentLocation = () => {
    return new Promise((resolve, reject) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;
            // alert("Location Fetched Successfully");
            resolve({ latitude, longitude });
          },
          (error) => {
            let errorMessage;
            switch (error.code) {
              case error.PERMISSION_DENIED:
                errorMessage = "User denied the request for Geolocation.";
                break;
              case error.POSITION_UNAVAILABLE:
                errorMessage = "Location information is unavailable.";
                break;
              case error.TIMEOUT:
                errorMessage = "The request to get user location timed out.";
                break;
              case error.UNKNOWN_ERROR:
                errorMessage = "An unknown error occurred.";
                break;
            }
            console.error("Geolocation error:", errorMessage);
            alert(errorMessage);
            reject(errorMessage);
          }
        );
      } else {
        const error = new Error(
          "Geolocation is not supported by this browser."
        );
        alert(error.message);
        reject(error.message);
      }
    });
  };

  const checkBaseLocationExistence = async (id) => {
    try {
      const response = await axios.get(
        `${api_url}/baselocation/checkbaselocation1/${id}`
      );
      console.log("Full response:", response.data);
      console.log(
        "isLocationAvailable value:",
        response.data.isLocationAvailable
      );
      if (response.data.isLocationAvailable === true) {
        setBaseLocation(true);
      }
    } catch (err) {
      setBaseLocation(true);
      toast.error(
        "Error fetching base location. Please contact administrator...!",
        {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          theme: "light",
        }
      );
    }
  };

  const handlegetPunches = async () => {
    try {
      const date = new Date();
      const currentDate = date.toISOString().split("T")[0];
      const result = fetchCurrentDateTime();
      const formattedDate = formatDateToMySQL(result.date);

      const payload = {
        AtlasEmpID: AtlasEmpID,
        PunchDate: formattedDate,
      };

      // Perform the API request
      const res = await axios.post(`${api_url}/punch/getlistofpunch1`, payload, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      // If successful, set the punch list
      setPunchList(res.data.data);
    } catch (error) {
      // Handle the error and display the toast notification
      console.error(error); // For debugging (you can remove this in production)

      // if (error.response && error.response.status === 500) {
      // You can also handle different error codes here (like 400, 404, etc.)
      toast.error(
        "Error fetching Punch Details. Please Contact Administrator...",
        {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          theme: "light",
          className: "custom-toast",
        }
      );
      // }
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        await checkBaseLocationExistence(AtlasEmpID);
        await handlegetPunches();
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [AtlasEmpID]);

  const getIpAddress = async () => {
    try {
      const response = await axios.get("https://api.ipify.org?format=json");
      return response.data.ip;
    } catch (error) {
      throw error;
    }
  };

  const handleCheckIn = async () => {
    setLoading(true);
    if (baseLocation === false) {
      setLoading(false);
      toast.error("Can't able to punching-in without base location approved", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        theme: "light",
        className: "custom-toast",
      });
      return;
    }

    const result = fetchCurrentDateTime();
    const { date: omanDate, time } = result; // Extract date and time
    const [day, month, year] = omanDate.split("/"); // Format it correctly
    const resultantdate = `${year}-${month}-${day}`;
    try {
      const location = await getCurrentLocation();
      const ipAddress = await getIpAddress();

      const { latitude, longitude } = location;

      if (!latitude || !longitude) {
        throw new Error("Location is not available");
      }
      if (!ipAddress) {
        throw new Error("IP Address is not available");
      }

      const isPunchedIn = await handleValidate();

      if (isPunchedIn === false) {
        const data = {
          userLocation: { latitude, longitude },
          IPAddress: ipAddress,
          AtlasEmpID: AtlasEmpID,
          EmpName: userData.EmpName,
          Department: userData.Department,
          Designation: userData.Designation,
          Operation: "punchin",
          // PunchDate: currentDate,
          // PunchInTime: currentTime,
          PunchDate: resultantdate,
          IsSiteVisit: siteVisit,
          PunchInTime: result.time,
        };

        const response = await axios.post(`${api_url}/createpunchdetail1`, data);

        if (response.data.success) {
          handlegetPunches();
          setSiteVisit(false);
          setLoading(false);
          toast.success("check-in successful", {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            theme: "light",
            className: "custom-toast",
          });
        } else if (
          response.data.message.trim() ===
          "Please punch within the appropriate location"
        ) {
          setLoading(false);
          toast.error("Please Punch within the appropriate Location", {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            theme: "light",
            className: "custom-toast",
          });
        } else {
          setLoading(false);
          toast.error("Error punch-in. Please contact administrator...!", {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            theme: "light",
            className: "custom-toast",
          });
        }
      }
    } catch (error) {
      console.error("Error during check-in:", error);
      setLoading(false);
      toast.error("Error punch-in. Please contact administrator...!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        theme: "light",
        className: "custom-toast",
      });
    }
  };

  const handleValidate = async () => {
    const date = new Date();
    const result = fetchCurrentDateTime();
    const formattedDate = formatDateToMySQL(result.date);
    // const currentDate = date.toISOString().split("T")[0];
    if (!AtlasEmpID || !formattedDate) {
      // alert("AtlasEmpID and PunchDate are required.");
      console.log("AtlasempId and PunchDate is needed");
      toast.error(
        "Error in punch operation. Please contact administrator...!",
        {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          theme: "light",
          className: "custom-toast",
        }
      );
      return false;
    }

    try {
      const payload = {
        AtlasEmpID: AtlasEmpID,
        PunchDate: formattedDate,
      };

      const response = await axios.post(`${api_url}/validatecheckin1`, payload, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.data.IsPunchedIn === true) {
        setLoading(false);
        toast.error("Already punched-in for today", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          theme: "light",
          className: "custom-toast",
        });
        console.log("Already Punched in");
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.error("Error during validation:", error);
      return false;
    }
  };

  const handleCheckOut = async () => {
    setLoading(true);
    if (baseLocation === false) {
      // alert("You cannot check out without a base location.");
      setLoading(false);
      toast.error("Can't able to punching-out without base location approved", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        theme: "light",
        className: "custom-toast",
      });
      return;
    }
    const date = new Date(); // Declare `date` here, before usage
    const currentDate = date.toISOString().split("T")[0];
    const currentTime = `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;

    const result = fetchCurrentDateTime();
    const { date: omanDate, time } = result; // Extract date and time
    const [day, month, year] = omanDate.split("/"); // Format it correctly
    const resultantdate = `${year}-${month}-${day}`;

    try {
      const location = await getCurrentLocation();
      const ipAddress = await getIpAddress();
      const { latitude, longitude } = location;

      if (!latitude || !longitude || !ipAddress) {
        throw new Error("Location or IP address not available");
      }

      const data = {
        userLocation: { latitude, longitude },
        IPAddress: ipAddress,
        AtlasEmpID: AtlasEmpID,
        EmpName: userData.EmpName,
        Department: userData.Department,
        Designation: userData.Designation,
        Operation: "punchout",
        // PunchDate: currentDate,
        // PunchOutTime: currentTime,
        PunchDate: resultantdate,
        IsSiteVisit: siteVisit,
        PunchOutTime: result.time,
      };

      const response = await axios.post(`${api_url}/createpunchdetail1`, data);

      if (response.data.success) {
        handlegetPunches();
        setLoading(false);
        toast.success("punched-out successfully", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          theme: "light",
          className: "custom-toast",
        });
      } else {
        setLoading(false);
        if (response.data.message === "Already Punched out...") {
          toast.error("You must punch in before punching out.", {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            theme: "light",
            className: "custom-toast",
          });
        } else if (
          response.data.message.trim() ===
          "Please punch within the appropriate location"
        ) {
          setLoading(false);
          toast.error("Please Punch within the appropriate Location", {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            theme: "light",
            className: "custom-toast",
          });
        } else {
          toast.error("Error punching-out. Please contact administrator...!", {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            theme: "light",
            className: "custom-toast",
          });
        }
      }
    } catch (error) {
      setLoading(false);
      console.error("Error during punching-out:", error);
      toast.error("Error punching-out. Please contact administrator...!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        theme: "light",
        className: "custom-toast",
      });
    }
  };

  const handleFetchBaseLocation = async () => {
    const loc = await getCurrentLocation();
    setLocation(loc);
    setMapSrc(
      `https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d1376.5732871228681!2d${loc.longitude}!3d${loc.latitude}!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e1!3m2!1sen!2som!4v1727679743582!5m2!1sen!2som`
    ); // Update mapSrc
  };

  const handleOpenModal = async () => {
    await handleFetchBaseLocation();
  };

  const handleBaseLocationSend = async () => {
    if (!location) return;
    try {
      await axios.post(
        `${api_url}/baselocation/sendbaselocation1`,
        {
          userLocation: location,
          AtlasEmpID: AtlasEmpID,
          EmpName: userData.EmpName,
          StatusInd: "P",
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      toast.success("Base location sent successfully!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        theme: "light",
        className: "custom-toast",
      });
    } catch (error) {
      console.error("Error sending base location:", error);
      // alert("base location error",error);
      toast.error(`Failed to send base location... Error:${error}`, {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        theme: "light",
        className: "custom-toast",
      });
    }
  };

  const handleCheckboxChange = (event) => {
    setSiteVisit(event.target.checked);
  };
  console.log("baselocation state value", baseLocation);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          {baseLocation == false ? (
            <button
              type="button"
              onClick={handleOpenModal}
              data-bs-toggle="modal"
              data-bs-target="#baselocationmodal"
              className="btn btn-outline-primary"
            >
              Click Here! to Send Base Location
            </button>
          ) : (
            ""
          )}

          {/* <span>click here to send the base location</span> */}
          <div className="punch-wrapper">
            <div className="d-flex gap-3">
              <div>
                <Button
                  type="button"
                  variant="contained"
                  color="primary"
                  onClick={handleCheckIn}
                  disabled={baseLocation === false}
                >
                  Punch In
                </Button>
              </div>
              <div>
                <Button
                  type="button"
                  variant="contained"
                  color="secondary"
                  onClick={handleCheckOut}
                  disabled={baseLocation === false}
                >
                  Punch Out
                </Button>
              </div>
            </div>
          </div>
          <div
            className="modal fade"
            id="baselocationmodal"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabIndex="-1"
            aria-labelledby="baselocationmodal"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-header">
                  <h1 className="modal-title fs-5" id="baselocationmodal">
                    Send Base Location
                  </h1>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body">
                  {location && (
                    <iframe
                      width="100%"
                      height="300"
                      style={{ border: 0 }}
                      loading="lazy"
                      allowFullScreen=""
                      src={mapSrc} // Use dynamic mapSrc here
                    ></iframe>
                  )}
                </div>
                <div className="modal-footer">
                  {/* <Link to="/userpunch"> */}
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    endIcon={<SendIcon />}
                    data-bs-dismiss="modal"
                    onClick={handleBaseLocationSend}
                  >
                    Send
                  </Button>
                  {/* </Link> */}
                </div>
              </div>
            </div>
          </div>
          <div className="container mt-5">
            <div className="punch-list-header">
              <div className="d-flex justify-content-end mb-2">
                <input
                  type="checkbox"
                  className="form-input-check me-2"
                  id="site-visit"
                  name="site-visit"
                  onChange={handleCheckboxChange}
                />
                <label className="form-label " htmlFor="site-visit">
                  Site Visit
                </label>
              </div>
            </div>
            <div className="border border-secondary punch-list-header">
              <div className="p-2 d-flex fw-bold shadow justify-content-center blockquote">
                Punch List - {AtlasEmpID}
              </div>
              <table className="table table-hover table-responsive">
                <thead>
                  <tr>
                    <th scope="col" className="table-header-font-size">
                      SNo
                    </th>
                    <th scope="col" className="table-header-font-size">
                      Punch Date
                    </th>
                    <th scope="col" className="table-header-font-size">
                      in-time
                    </th>
                    <th scope="col" className="table-header-font-size">
                      out-time
                    </th>
                  </tr>
                </thead>
                <tbody className="table-body-font-size">
                  {punchList.map((item, index) => {
                    const sno = index + 1;
                    return (
                      <tr className="py-0" key={index}>
                        <td className="py-0">{sno}</td>
                        <td className="py-0">{item.PunchDate}</td>
                        <td className="py-0">{item.PunchInTime}</td>
                        <td className="py-0">{item.PunchOutTime}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default TestFile;

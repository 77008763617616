import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import { Menu } from "../../header/Header";
import "./CircularForm.css";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import SendIcon from "@mui/icons-material/Send";
import Loader from "../../Loader";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; 

const CircularForm = () => {
  const api_url = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const userData = JSON.parse(sessionStorage.getItem("CurrentUserData"));
  if((userData.AtlasEmpID.trim() !=="AEC002")&&(userData.AtlasEmpID.trim() !=="AEC057")&&(userData.AtlasEmpID.trim() !=="AEC052")){
    navigate("/user/userpunch")
  }
  if (!token) {
    navigate("/");
  }
  const [AddressTO, setAddressTO] = useState("");
  const [AddressCC, setAddressCC] = useState("rahima@atlas-om.com");
  const [AddressBCC, setAddressBCC] = useState("");
  const [MailSubject, setMailSubject] = useState("");
  const [MailMessage, setMailMessage] = useState("");
  const [MailAttachments, setMailAttachments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedDepartments, setSelectedDepartments] = useState([]);
  const [departments, SetDepartments] = useState([]);
  const [circularType, setCircularType] = useState(null);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const circulartypes = [
    "Accession Day",
    "Eid al-Fitr",
    "Eid al-Adha",
    "Hijri New Year",
    "Islamic New Year",
    "Oman National Day",
    "Prophet Mohammed’s Birthday",
    "Ramadan Time Change",
    "Renaissance Day",
    "Other",
  ];

 
  useEffect(() => {
    fetchDepartments();
  }, [circularType]);

  useEffect(() => {
    // Whenever the start or end date changes, update the message
    if (circularType) {
      updateMessage(circularType);
    }
  }, [startDate, endDate]);

  const fetchDepartments = async () => {
    try {
      const res = await axios.get(`${api_url}/emp/GetDepartments`);
      if (res.data.success === true) {
        SetDepartments(res.data.results);
        setLoading(false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleChange = (e) => {
    let value = e.target.checked;
    const deptName = e.target.value;

    if (deptName === "All") {
      if (value) {
        // Select all departments
        const allDepartmentNames = departments.map(
          (dept) => dept.DepartmentName
        );
        setSelectedDepartments(allDepartmentNames);
      } else {
        // Clear selection
        setSelectedDepartments([]);
      }
    } else {
      // Handle individual department checkboxes
      if (value) {
        setSelectedDepartments((prev) => [...prev, deptName]);
      } else {
        setSelectedDepartments((prev) =>
          prev.filter((dept) => dept !== deptName)
        );
      }
    }
  };

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    setMailAttachments(files);
  };

  const handleMailID = async () => {
    let payload;
    if (selectedDepartments.includes("All")) {
      // If "All" is selected, use all departments
      const allDepartmentNames = departments.map((dept) => dept.DepartmentName);
      payload = { depts: allDepartmentNames };
    } else {
      // Use selected departments
      payload = { depts: selectedDepartments };
    }

    await axios
      .post(`${api_url}/circulars/getEmailIDs`, payload)
      .then((res) => {
        let results = res.data.result;
        setAddressTO(results);
      })
      .catch((err) => {
        console.log("Error fetching Mail ID's", err);
      });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("AddressTO", AddressTO);
    formData.append("AddressCC", AddressCC);
    formData.append("AddressBCC", AddressBCC);
    formData.append("MailSubject", MailSubject);
    formData.append("MailMessage", MailMessage);

    MailAttachments.forEach((file) => {
      formData.append("MailAttachments", file);
    });

    try {
      await axios.post(`${api_url}/circulars/sendCircular`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      toast.success("Email sent Successfully!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        theme: "light",
      });
    } catch (error) {
      console.error("Failed to send email:", error);
    }
  };

  const handleDropdownchange = (e) => {
    const selectedType = e.target.value;
    setCircularType(selectedType);

    // Update the subject
    if (circularMapping[selectedType]) {
      setMailSubject(circularMapping[selectedType].subject);
    }

    // Update the message with dynamic dates
    updateMessage(selectedType);
  };

  const handleEditorChange = (value) => {
    setMailMessage(value); // Update MailMessage with rich-text editor's value
  };


  const updateMessage = (selectedType) => {
    console.log("Updating message for circular type:", selectedType);

    if (selectedType === "Other") {
      setMailMessage(""); 
      setMailSubject("");
    } else if (circularMapping[selectedType]) {
      // Handle Hijri New Year separately
      if (selectedType === "Hijri New Year") {
        const message = circularMapping[selectedType].message(
          startDate || "Start Date"
        );
        console.log("Generated message for Hijri New Year:", message); // Debugging line
        setMailMessage(message); // Update the message state with the dynamic content
      } else {
        const message = circularMapping[selectedType].message(
          startDate || "Start Date", // Fallback to "Start Date" if not selected
          endDate || "End Date"
        );
        setMailMessage(message);
      }
    }
  };

  // const updateMessage = (selectedType) => {
  //   console.log("Updating message for circular type:", selectedType);

  //   if (selectedType === "Other") {
  //     setMailMessage(""); // Clear the message field
  //     setMailSubject("Custom Circular Subject");
  //   } else if (circularMapping[selectedType]) {
  //     // Handle Hijri New Year separately
  //     if (selectedType === "Hijri New Year") {
  //       const message = circularMapping[selectedType].message(
  //         startDate || "Start Date"
  //       );
  //       console.log("Generated message for Hijri New Year:", message); // Debugging line
  //       setMailMessage(message); // Update the message state with the dynamic content
  //     } else if (selectedType === "Eid al-Fitr") {
  //       const message = circularMapping[selectedType].message(
  //         startDate || "Start Date", // Pass both startDate and endDate for Eid al-Fitr
  //         endDate || "End Date"
  //       );
  //       console.log("Generated message for Eid al-Fitr:", message); // Debugging line
  //       setMailMessage(message); // Update the message state with the dynamic content
  //     } else {
  //       const message = circularMapping[selectedType].message(
  //         startDate || "Start Date", // Fallback to "Start Date" if not selected
  //         endDate || "End Date"
  //       );
  //       setMailMessage(message); // Update the message for other circular types
  //     }
  //   }
  // };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0"); // Add leading zero if day < 10
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Add leading zero if month < 10
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
  };

  const getDayOfWeek = (date) => {
    const daysOfWeek = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    return daysOfWeek[date.getDay()];
  };

  const circularMapping = {
    "Accession Day": {
      subject: "[Atlas - Family] Accession Day Holiday",
      message: (startDate) => {
        if (startDate === "Start Date") {
          return `<p style="font-size: 16px; font-family: Arial, sans-serif; margin-bottom:20px; padding-bottom:10px;"><b>Dear All,</b></p>
              <p style="font-size: 16px; font-family: Arial, sans-serif;">We would like to inform you that <b><u>Day of the week</u> ${startDate}</b> will be an official holiday (Accession Day) for all employees.</p>
              <p style="font-size: 16px; font-family: Arial, sans-serif;">We expect you back at work on <u>Day of the week</u>, <u>Back at work date</u>.</p>
              <p style="font-size: 16px; font-family: Arial, sans-serif;"><b>Happy Holiday to all of you...!</b></p>
              <p style="font-size: 16px; font-family: Arial, sans-serif; margin-top:20px; padding-top:10px;">Thanks &amp; Regards,</p>
              <p style="font-size: 16px; font-family: Arial, sans-serif;">HR - Atlas</p>`;
        }

        // Ensure the startDate is a valid Date object
        const formattedStartDate = new Date(startDate);

        // Check if the date is valid
        if (isNaN(formattedStartDate)) {
          console.error("Invalid date:", startDate);
          return "Invalid date provided";
        }

        // Get the day of the week for the start date (e.g., Monday, Tuesday, etc.)
        const startDay = getDayOfWeek(formattedStartDate);

        // Calculate the next working day (back to work date)
        const nextDay = new Date(formattedStartDate);
        nextDay.setDate(nextDay.getDate() + 1); // Move to the next day

        // Handle if the next day is Friday (5) or Saturday (6), and move to Sunday
        if (nextDay.getDay() === 5) {
          nextDay.setDate(nextDay.getDate() + 2); // Skip to Sunday
        } else if (nextDay.getDay() === 6) {
          nextDay.setDate(nextDay.getDate() + 1); // Skip to Sunday
        }

        // Format the back to work date
        const formattedBackAtWorkDate = formatDate(nextDay);
        const backAtWorkDay = getDayOfWeek(nextDay);

        // Return the message with dynamically updated start and back-to-work details
        return `<p style="font-size: 16px; font-family: Arial, sans-serif; margin-bottom:20px; padding-bottom:10px;"><b>Dear All,</b></p>
            <p style="font-size: 16px; font-family: Arial, sans-serif;">We would like to inform you that <b><u>${startDay}</u> ${formattedStartDate.toLocaleDateString()}</b> will be an official holiday (Accession Day) for all employees.</p>
            <p style="font-size: 16px; font-family: Arial, sans-serif;">We expect you back at work on <b><u>${backAtWorkDay}, ${formattedBackAtWorkDate}.</u></b></p>
            <p style="font-size: 16px; font-family: Arial, sans-serif;"><b>Happy Holiday to all of you...!</b></p>
            <p style="font-size: 16px; font-family: Arial, sans-serif; margin-top:20px; padding-top:10px;">Thanks &amp; Regards,</p>
            <p style="font-size: 16px; font-family: Arial, sans-serif;">HR - Atlas</p>`;
      },
    },
    "Eid al-Fitr": {
      subject: "[Atlas Family] - Eid Al Fitr Holiday",
      message: (startDate, endDate) => {
        if (startDate === "Start Date" || endDate === "End Date") {
          return `<p style="font-size: 16px; font-family: Arial, sans-serif; margin-bottom:20px; padding-bottom:10px;"><b>Dear All,</b></p>
        <p style="font-size: 16px; font-family: Arial, sans-serif;">We would like to inform you that <b>Eid holiday</b> will be from <b>${startDate}</b> to <b>${endDate}</b>.</p>
        <p style="font-size: 16px; font-family: Arial, sans-serif;">We expect you back at work on <u>Day of the week</u>, <u>Back at work date</u>.</p>
        <p style="font-size: 16px; font-family: Arial, sans-serif;"><b>We wish you a happy Eid with your loved ones...!</b></p>
        <p style="font-size: 16px; font-family: Arial, sans-serif; margin-top:20px; padding-top:10px;">Thanks &amp; Regards,</p>
      <p style="font-size: 16px; font-family: Arial, sans-serif;">HR - Atlas</p>`;
        }

        // Format the dates to dd-mm-yyyy
        const formattedStartDate = formatDate(startDate);
        const formattedEndDate = formatDate(endDate);

        // Get the next day (the day after the endDate)
        const nextDay = new Date(endDate);
        nextDay.setDate(nextDay.getDate() + 1); // Set to next day

        // Check if the next day is Friday or Saturday
        let backAtWorkDate = nextDay;
        if (nextDay.getDay() === 5) {
          // If next day is Friday
          backAtWorkDate = new Date(nextDay.setDate(nextDay.getDate() + 2)); // Skip to Sunday
        } else if (nextDay.getDay() === 6) {
          // If next day is Saturday
          backAtWorkDate = new Date(nextDay.setDate(nextDay.getDate() + 1)); // Skip to Sunday
        }

        // Format the back at work date
        const formattedBackAtWorkDate = formatDate(backAtWorkDate);

        // Get the day of the week for the back at work date
        const backAtWorkDay = getDayOfWeek(backAtWorkDate);

        return `<p style="font-size: 16px; font-family: Arial, sans-serif; margin-bottom:20px; padding-bottom:10px;"><b>Dear All,</b></p>
      <p style="font-size: 16px; font-family: Arial, sans-serif;">We would like to inform you that <b>Eid holiday</b> will be from <b>${formattedStartDate}</b> to <b>${formattedEndDate}</b>.</p>
      <p style="font-size: 16px; font-family: Arial, sans-serif;">We expect you back at work on <u>${backAtWorkDay}, ${formattedBackAtWorkDate}</u>.</p>
      <p style="font-size: 16px; font-family: Arial, sans-serif;"><b>We wish you a happy Eid with your loved ones...!</b></p>
      <p style="font-size: 16px; font-family: Arial, sans-serif; margin-top:20px; padding-top:10px;">Thanks &amp; Regards,</p>
      <p style="font-size: 16px; font-family: Arial, sans-serif;">HR - Atlas</p>`;
      },
    },
    "Eid al-Adha": {
      subject: "[Atlas Family] - Eid Al Adha Holiday",
      message: (startDate, endDate) => {
        if (startDate === "Start Date" || endDate === "End Date") {
          return `<p style="font-size: 16px; font-family: Arial, sans-serif; margin-bottom:20px; padding-bottom:10px;"><b>Dear All,</b></p>
            <p style="font-size: 16px; font-family: Arial, sans-serif;">We would like to inform you that <b>Eid holiday</b> will be from <b>${startDate}</b> to <b>${endDate}</b>.</p>
            <p style="font-size: 16px; font-family: Arial, sans-serif;">We expect you back at work on <u>Day of the week</u>, <u>Back at work date</u>.</p>
            <p style="font-size: 16px; font-family: Arial, sans-serif;"><b>Wishing you and your Family a Happy Eid...</b></p>
            <p style="font-size: 16px; font-family: Arial, sans-serif; margin-top:20px; padding-top:10px;">Thanks &amp; Regards,</p>
          <p style="font-size: 16px; font-family: Arial, sans-serif;">HR - Atlas</p>`;
        }

        // Format the dates to dd-mm-yyyy
        const formattedStartDate = formatDate(startDate);
        const formattedEndDate = formatDate(endDate);

        // Get the next day (the day after the endDate)
        const nextDay = new Date(endDate);
        nextDay.setDate(nextDay.getDate() + 1); // Set to next day

        // Check if the next day is Friday or Saturday
        let backAtWorkDate = nextDay;
        if (nextDay.getDay() === 5) {
          // If next day is Friday
          backAtWorkDate = new Date(nextDay.setDate(nextDay.getDate() + 2)); // Skip to Sunday
        } else if (nextDay.getDay() === 6) {
          // If next day is Saturday
          backAtWorkDate = new Date(nextDay.setDate(nextDay.getDate() + 1)); // Skip to Sunday
        }

        // Format the back at work date
        const formattedBackAtWorkDate = formatDate(backAtWorkDate);

        // Get the day of the week for the back at work date
        const backAtWorkDay = getDayOfWeek(backAtWorkDate);

        return `<p style="font-size: 16px; font-family: Arial, sans-serif; margin-bottom:20px; padding-bottom:10px;"><b>Dear All,</b></p>
          <p style="font-size: 16px; font-family: Arial, sans-serif;">We would like to inform you that <b>Eid holiday</b> will be from <b>${formattedStartDate}</b> to <b>${formattedEndDate}</b>.</p>
          <p style="font-size: 16px; font-family: Arial, sans-serif;">We expect you back at work on <u>${backAtWorkDay}, ${formattedBackAtWorkDate}</u>.</p>
          <p style="font-size: 16px; font-family: Arial, sans-serif;"><b>Wishing you and your Family a Happy Eid...</b></p>
         <p style="font-size: 16px; font-family: Arial, sans-serif; margin-top:20px; padding-top:10px;">Thanks &amp; Regards,</p>
          <p style="font-size: 16px; font-family: Arial, sans-serif;">HR - Atlas</p>`;
      },
    },
    "Hijri New Year": {
      subject: "[Atlas - Family] Hijri New Year Holiday",
      message: (startDate) => {
        // If startDate is not selected (default), show the fallback message
        if (startDate === "Start Date") {
          return `<p style="font-size: 16px; font-family: Arial, sans-serif; margin-bottom:20px; padding-bottom:10px;"><b>Dear All,</b></p>
                  <p style="font-size: 16px; font-family: Arial, sans-serif;">We would like to inform you that <b><u>Day of the week</u> ${startDate}</b> will be an official holiday for all employees.</p>
                  <p style="font-size: 16px; font-family: Arial, sans-serif;">We expect you back at work on <u>Day of the week</u>, <u>Back at work date</u>.</p>
                  <p style="font-size: 16px; font-family: Arial, sans-serif;"><b>Happy Holiday to all of you!~</b></p>
                  <p style="font-size: 16px; font-family: Arial, sans-serif; margin-top:20px; padding-top:10px;">Thanks &amp; Regards,</p>
                  <p style="font-size: 16px; font-family: Arial, sans-serif;">HR - Atlas</p>`;
        }

        // Format the start date to a valid Date object
        // const formattedStartDate = new Date(startDate);
        const formattedStartDate = formatDate(startDate);

        // Get the day of the week for the start date
        const holidayDay = getDayOfWeek(new Date(startDate));

        // Calculate the next working day (back to work date)
        const nextDay = new Date(startDate);
        nextDay.setDate(nextDay.getDate() + 1); // Set to the day after the holiday

        // Check if the next day is Friday or Saturday
        let backAtWorkDate = nextDay;
        if (nextDay.getDay() === 5) {
          // If the next day is Friday, skip to Sunday
          backAtWorkDate = new Date(nextDay.setDate(nextDay.getDate() + 2)); // Skip to Sunday
        } else if (nextDay.getDay() === 6) {
          // If the next day is Saturday, skip to Sunday
          backAtWorkDate = new Date(nextDay.setDate(nextDay.getDate() + 1)); // Skip to Sunday
        }

        // Format the back at work date
        const formattedBackAtWorkDate = formatDate(backAtWorkDate);

        // Get the day of the week for the back at work date
        const backAtWorkDay = getDayOfWeek(backAtWorkDate);

        // Return the message with dynamically updated start and back-to-work details
        return `<p style="font-size: 16px; font-family: Arial, sans-serif; margin-bottom:20px; padding-bottom:10px;"><b>Dear All,</b></p>
                <p style="font-size: 16px; font-family: Arial, sans-serif;">We would like to inform you that <b><u>${holidayDay}, ${formattedStartDate}</u></b> will be an official holiday for all employees.</p>
                <p style="font-size: 16px; font-family: Arial, sans-serif;">We expect you back at work on <b><u>${backAtWorkDay}, ${formattedBackAtWorkDate}</u></b>.</p>
                <p style="font-size: 16px; font-family: Arial, sans-serif;"><b>Happy Holiday to all of you!</b></p>
                <p style="font-size: 16px; font-family: Arial, sans-serif; margin-top:20px; padding-top:10px;">Thanks &amp; Regards,</p>
                <p style="font-size: 16px; font-family: Arial, sans-serif;">HR - Atlas</p>`;
      },
    },
    "Islamic New Year": {
      subject: "[Atlas - Family] Islamic New Year Holiday",
      message: (startDate) => {
        if (startDate === "Start Date") {
          return `<p style="font-size: 16px; font-family: Arial, sans-serif; margin-bottom:20px; padding-bottom:10px;"><b>Dear All,</b></p>
              <p style="font-size: 16px; font-family: Arial, sans-serif;">We would like to inform you that <b><u>Day of the week</u> ${startDate}</b> will be an official holiday (Islamic New Year) for all employees.</p>
              <p style="font-size: 16px; font-family: Arial, sans-serif;">We expect you back at work on <u>Day of the week</u>, <u>Back at work date</u>.</p>
              <p style="font-size: 16px; font-family: Arial, sans-serif;"><b>Happy Holiday to all of you...!</b></p>
              <p style="font-size: 16px; font-family: Arial, sans-serif; margin-top:20px; padding-top:10px;">Thanks &amp; Regards,</p>
              <p style="font-size: 16px; font-family: Arial, sans-serif;">HR - Atlas</p>`;
        }

        // Ensure the startDate is a valid Date object
        const formattedStartDate = new Date(startDate);

        // Check if the date is valid
        if (isNaN(formattedStartDate)) {
          console.error("Invalid date:", startDate);
          return "Invalid date provided";
        }

        // Get the day of the week for the start date (e.g., Monday, Tuesday, etc.)
        const startDay = getDayOfWeek(formattedStartDate);

        // Calculate the next working day (back to work date)
        const nextDay = new Date(formattedStartDate);
        nextDay.setDate(nextDay.getDate() + 1); // Move to the next day

        // Handle if the next day is Friday (5) or Saturday (6), and move to Sunday
        if (nextDay.getDay() === 5) {
          nextDay.setDate(nextDay.getDate() + 2); // Skip to Sunday
        } else if (nextDay.getDay() === 6) {
          nextDay.setDate(nextDay.getDate() + 1); // Skip to Sunday
        }

        // Format the back to work date
        const formattedBackAtWorkDate = formatDate(nextDay);
        const backAtWorkDay = getDayOfWeek(nextDay);

        // Return the message with dynamically updated start and back-to-work details
        return `<p style="font-size: 16px; font-family: Arial, sans-serif; margin-bottom:20px; padding-bottom:10px;"><b>Dear All,</b></p>
            <p style="font-size: 16px; font-family: Arial, sans-serif;">We would like to inform you that <b><u>${startDay}</u> ${formattedStartDate.toLocaleDateString()}</b> will be an official holiday (Islamic New Year) for all employees.</p>
            <p style="font-size: 16px; font-family: Arial, sans-serif;">We expect you back at work on <b><u>${backAtWorkDay}, ${formattedBackAtWorkDate}.</u></b></p>
            <p style="font-size: 16px; font-family: Arial, sans-serif;"><b>Happy Holiday to all of you...!</b></p>
            <p style="font-size: 16px; font-family: Arial, sans-serif; margin-top:20px; padding-top:10px;">Thanks &amp; Regards,</p>
            <p style="font-size: 16px; font-family: Arial, sans-serif;">HR - Atlas</p>`;
      },
    },
    "Oman National Day": {
      subject: "[Atlas Family] - National Day Holiday",
      message: (startDate, endDate) => {
        if (startDate === "Start Date" || endDate === "End Date") {
          return `<p style="font-size: 16px; font-family: Arial, sans-serif; margin-bottom:20px; padding-bottom:10px;"><b>Dear All,</b></p>
            <p style="font-size: 16px; font-family: Arial, sans-serif;">We would like to inform you that National day holiday will be from <b>${startDate}</b> to <b>${endDate}</b>.</p>
            <p style="font-size: 16px; font-family: Arial, sans-serif;">We expect you back at work on <u><b>Day of the week</b></u>, <u><b>Back at work date</b></u>.</p>
            <p style="font-size: 16px; font-family: Arial, sans-serif;"><b>Happy Holiday to all of you...!</b></p>
            <p style="font-size: 16px; font-family: Arial, sans-serif; margin-top:20px; padding-top:10px;">Thanks &amp; Regards,</p>
          <p style="font-size: 16px; font-family: Arial, sans-serif;">HR - Atlas</p>`;
        }

        // Format the dates to dd-mm-yyyy
        const formattedStartDate = formatDate(startDate);
        const formattedEndDate = formatDate(endDate);

        // Get the next day (the day after the endDate)
        const nextDay = new Date(endDate);
        nextDay.setDate(nextDay.getDate() + 1); // Set to next day

        // Check if the next day is Friday or Saturday
        let backAtWorkDate = nextDay;
        if (nextDay.getDay() === 5) {
          // If next day is Friday
          backAtWorkDate = new Date(nextDay.setDate(nextDay.getDate() + 2)); // Skip to Sunday
        } else if (nextDay.getDay() === 6) {
          // If next day is Saturday
          backAtWorkDate = new Date(nextDay.setDate(nextDay.getDate() + 1)); // Skip to Sunday
        }

        // Format the back at work date
        const formattedBackAtWorkDate = formatDate(backAtWorkDate);

        // Get the day of the week for the back at work date
        const backAtWorkDay = getDayOfWeek(backAtWorkDate);

        return `<p style="font-size: 16px; font-family: Arial, sans-serif; margin-bottom:20px; padding-bottom:10px;"><b>Dear All,</b></p>
          <p style="font-size: 16px; font-family: Arial, sans-serif;">We would like to inform you that National day holiday will be from <b>${formattedStartDate}</b> to <b>${formattedEndDate}</b>.</p>
          <p style="font-size: 16px; font-family: Arial, sans-serif;">We expect you back at work on <u><b>${backAtWorkDay}, ${formattedBackAtWorkDate}</b></u>.</p>
          <p style="font-size: 16px; font-family: Arial, sans-serif;"><b>Happy Holiday to all of you...!</b></p>
         <p style="font-size: 16px; font-family: Arial, sans-serif; margin-top:20px; padding-top:10px;">Thanks &amp; Regards,</p>
          <p style="font-size: 16px; font-family: Arial, sans-serif;">HR - Atlas</p>`;
      },
    },
    "Prophet Mohammed’s Birthday": {
      subject:
        "[Atlas - Family] Notice of Holiday - Prophet Mohammed's Birthday",
      message: (startDate) => {
        // Check if startDate is valid or fallback to a default message
        if (startDate === "Start Date" || !startDate) {
          return `<p style="font-size: 16px; font-family: Arial, sans-serif; margin-bottom:20px; padding-bottom:10px;"><b>Dear All,</b></p>
        <p style="font-size: 16px; font-family: Arial, sans-serif;">We would like to inform you that <u>Leave Day</u> <b><u>LeaveDate</u></b> is a public holiday (Prophet's birthday), and the working day will be on <u>Working Day, <b>Working Day Date</b></u>.</p>
        <p style="font-size: 16px; font-family: Arial, sans-serif;"><b>Happy Holiday to all of you!</b></p>
        <p style="font-size: 16px; font-family: Arial, sans-serif; margin-top:20px; padding-top:10px;">Thanks &amp; Regards,</p>
        <p style="font-size: 16px; font-family: Arial, sans-serif;">HR - Atlas</p>`;
        }

        // Ensure the startDate is a valid Date object
        const formattedStartDate = new Date(startDate);

        // Check if the date is valid
        if (isNaN(formattedStartDate)) {
          console.error("Invalid date:", startDate);
          return "Invalid date provided"; // Fallback message for invalid date
        }

        // Get the day of the week for the start date (e.g., Monday, Tuesday, etc.)
        const startDay = getDayOfWeek(formattedStartDate);

        // Calculate the next working day (back to work date)
        const nextDay = new Date(formattedStartDate);
        nextDay.setDate(nextDay.getDate() + 1); // Move to the next day

        // Handle if the next day is Friday (5) or Saturday (6), and move to Sunday
        if (nextDay.getDay() === 5) {
          nextDay.setDate(nextDay.getDate() + 2); // Skip to Sunday
        } else if (nextDay.getDay() === 6) {
          nextDay.setDate(nextDay.getDate() + 1); // Skip to Sunday
        }

        // Format the back to work date
        const formattedBackAtWorkDate = formatDate(nextDay);
        const backAtWorkDay = getDayOfWeek(nextDay);

        // Return the message with dynamically updated start and back-to-work details
        return `<p style="font-size: 16px; font-family: Arial, sans-serif; margin-bottom:20px; padding-bottom:10px;"><b>Dear All,</b></p>
            <p style="font-size: 16px; font-family: Arial, sans-serif;">We would like to inform you that  ${startDay}, <b><u>${formattedStartDate.toLocaleDateString()}</u></b> is a public holiday (Prophet's birthday) and the working day will be on ${backAtWorkDay}, <u><b>${formattedBackAtWorkDate}</b></u>.</p>
            <p style="font-size: 16px; font-family: Arial, sans-serif;"><b>Happy Holiday to all of you!</b></p>
            <p style="font-size: 16px; font-family: Arial, sans-serif; margin-top:20px; padding-top:10px;">Thanks &amp; Regards,</p>
            <p style="font-size: 16px; font-family: Arial, sans-serif;">HR - Atlas</p>`;
      },
    },
    "Ramadan Time Change": {
      subject: "[Atlas Family] - Ramadan Time Change",
      message: (startDate, endDate) => {
        if (startDate === "Start Date" || endDate === "End Date") {
          return `<p style="font-size: 16px; font-family: Arial, sans-serif; margin-bottom:20px; padding-bottom:10px;"><b>Dear All,</b></p>
            <p style="font-size: 16px; font-family: Arial, sans-serif;">It was decided that the official working hours for employees during the holy month of Ramadan(Start Date  to  End Date) starting from 9 am to 2 pm.</p>
            <p style="font-size: 16px; font-family: Arial, sans-serif; margin-top:20px; padding-top:10px;">Thanks &amp; Regards,</p>
          <p style="font-size: 16px; font-family: Arial, sans-serif;">HR - Atlas</p>`;
        }

        // Format the dates to dd-mm-yyyy
        const formattedStartDate = formatDate(startDate);
        const formattedEndDate = formatDate(endDate);

        return `<p style="font-size: 16px; font-family: Arial, sans-serif; margin-bottom:20px; padding-bottom:10px;"><b>Dear All,</b></p>
            <p style="font-size: 16px; font-family: Arial, sans-serif;">It was decided that the official working hours for employees during the holy month of Ramadan(${formattedStartDate}  to  ${formattedEndDate}) starting from 9 am to 2 pm.</p>
            <p style="font-size: 16px; font-family: Arial, sans-serif; margin-top:20px; padding-top:10px;">Thanks &amp; Regards,</p>
          <p style="font-size: 16px; font-family: Arial, sans-serif;">HR - Atlas</p>`;
      },
    },
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Menu link="/circulars" />

          <div className="circular-wrapper">
            <div className="container py-3 border border-secondary circular-form-wrapper">
              <div className="d-flex justify-content-between align-items-center mb-3">
                <div className="cir-type-select">
                  <label className="form-label mt-1" htmlFor="circularType">
                    Circular Type
                  </label>
                  <select
                    id="circularType"
                    onChange={handleDropdownchange}
                    value={circularType}
                    name="circularType"
                    className="ms-3 form-input-select"
                    required
                  >
                    <option value="">Select circular type</option>
                    {circulartypes.map((item, index) => (
                      <option key={index} value={item}>
                        {item}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="cir-date-picker-wrapper">
                  <div className="cir-start-date">
                    <label htmlFor="startDate" className="form-label">
                      Start Date
                    </label>
                    <input
                      type="date"
                      id="startDate"
                      className="form-input-date"
                      value={startDate}
                      onChange={(e) => setStartDate(e.target.value)}
                    />
                  </div>

                  {/* Conditionally render the End Date field */}
                  {circularType?.trim() !== "Accession Day" &&
                  circularType?.trim() !== "Hijri New Year" &&
                  circularType?.trim() !== "Islamic New Year" &&
                  circularType?.trim() !== "Prophet Mohammed’s Birthday" &&
                  circularType?.trim() !== "Renaissance Day" ? (
                    <div className="cir-end-date">
                      <label htmlFor="endDate" className="form-label">
                        End Date
                      </label>
                      <input
                        type="date"
                        id="endDate"
                        className="form-input-date"
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                      />
                    </div>
                  ) : null}
                </div>
              </div>

              <div className="row">
                <div className="col-8">
                  <form onSubmit={handleSubmit}>
                    <div className="form-floating mb-3">
                      <textarea
                        className="form-input-textarea text-area-field"
                        placeholder=""
                        id="to"
                        value={AddressTO}
                        onChange={(e) => setAddressTO(e.target.value)}
                      ></textarea>
                      <label className="form-label" htmlFor="to">
                        TO
                      </label>
                    </div>
                    <div className="form-floating mb-3">
                      <input
                        className="form-input-text"
                        type="text"
                        placeholder="CC"
                        id="cc"
                        value={AddressCC}
                        disabled
                        onChange={(e) => setAddressCC(e.target.value)}
                      />
                      <label className="form-label" htmlFor="cc">
                        CC
                      </label>
                    </div>
                    <div className="form-floating mb-3">
                      <input
                        className="form-input-text"
                        type="text"
                        placeholder="Subject"
                        value={MailSubject}
                        onChange={(e) => setMailSubject(e.target.value)}
                        required
                      />
                      <label className="form-label" htmlFor="subject">
                        SUBJECT
                      </label>
                    </div>
                    <div className="form-floating mb-3">
                      {/* {circularType === "Other" ? (
                        // If circular type is "Other", use a plain textarea
                        <textarea
                          className="form-input-textarea circular-message-textarea"
                          placeholder="Type Here...!"
                          id="message"
                          value={MailMessage} // Bind the MailMessage state
                          onChange={(e) => setMailMessage(e.target.value)} // Update the state with the user input
                        />
                      ) : (
                        // Otherwise, use the dangerouslySetInnerHTML for rich text
                        <div
                          className="form-input-textarea circular-message-textarea"
                          contentEditable={true}
                          onInput={(e) => setMailMessage(e.target.innerHTML)}
                          dangerouslySetInnerHTML={{ __html: MailMessage }}
                        />
                      )} */}
                        {circularType === "Other" ? (
                        // Use React Quill for rich-text editing
                        <ReactQuill
                          value={MailMessage} // Set the value of the editor
                          onChange={handleEditorChange} // Update the state with editor value
                          modules={{
                            toolbar: [
                              [{ 'header': '1' }, { 'header': '2' }],
                              [{ 'font': [] }],
                              [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                              ['bold', 'italic', 'underline'],
                              [{ 'align': [] }],
                              ['link', 'image'],
                              ['blockquote'],
                              [{ 'indent': '-1' }, { 'indent': '+1' }],
                              ['clean']
                            ],
                          }}
                          placeholder="Type your message here..." // Placeholder for the editor
                          className="form-input-textarea circular-message-textarea"
                        />
                      ) : (
                        // Fallback for non-rich-text editing (simple textarea)
                        <div
                          className="form-input-textarea circular-message-textarea"
                          contentEditable={true}
                          onInput={(e) => setMailMessage(e.target.innerHTML)}
                          dangerouslySetInnerHTML={{ __html: MailMessage }}
                        />
                      )}
                      <label className="form-label" htmlFor="message">
                        MESSAGE
                      </label>
                    </div>

                    <div className="mb-3">
                      <label htmlFor="formFileMultiple" className="form-label">
                        ATTACHMENTS
                      </label>
                      <input
                        onChange={handleFileChange}
                        className="form-control"
                        type="file"
                        id="formFileMultiple"
                        multiple
                      />
                    </div>
                    <div className="d-flex justify-content-end">
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        endIcon={<SendIcon />}
                      >
                        Send
                      </Button>
                    </div>
                  </form>
                </div>
                <div className="col-4">
                  <div className="select-department">
                    <h5 className="sub-wrapper-heading">Select Department</h5>

                    {departments.map((item, index) => (
                      <div key={index}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              value={item.DepartmentName}
                              onChange={handleChange}
                            />
                          }
                          label={item.DepartmentName}
                        />
                      </div>
                    ))}
                    <div>
                      <FormControlLabel
                        control={
                          <Checkbox value="All" onChange={handleChange} />
                        }
                        label="All"
                      />
                    </div>
                    <div className="buttons d-flex align-items-center justify-content-start mt-4">
                      <div>
                        <Button
                          variant="outlined"
                          size="small"
                          onClick={handleMailID}
                        >
                          Pick
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default CircularForm;

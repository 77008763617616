import React, { useState,useEffect } from 'react';
import "./ApprovalDashboard.css";
import Loader from '../Loader';
import { useNavigate,Link } from 'react-router-dom';
import { RiFolderUserFill,RiFileUserLine  } from "react-icons/ri";
import { FaPlaneCircleExclamation,FaFileCircleCheck } from "react-icons/fa6";
import { TbLocationCheck } from "react-icons/tb";

const ApprovalDashboard = ({onToggle}) => {
    const [loading,setLoading] = useState(true);
    const navigate = useNavigate();
    const userData = JSON.parse(sessionStorage.getItem("CurrentUserData"));
    const token = localStorage.getItem("token");
  useEffect(()=>{
    if (userData.IsReportingManager !== 1 && userData.AtlasEmpID !== "AEC057") {
        navigate("/user/userpunch")
      }
      else{
        setLoading(false);
      }
  },[])
  
    if (!token) {
      navigate("/");
    }
  

  return (
    <>
    {loading?<Loader/>: <div className="container-fluid page-title">
          <div className="container my-3 px-2">
          <h4 className="mb-2">Subordinates</h4>
            <div className="row g-4">
              <div className="col-12 col-md-6 col-lg-2" onClick={onToggle}>
              <Link to="/punch/baselocation/approvallist" className="text-decoration-none">
                <div className="border dashboard-card-wrapper employees">
                  <div className="fs-6 fw-semibold d-flex flex-column align-items-center">
                    Base Location Approval
                    <TbLocationCheck className="dashboard-card-icon" />
                  </div>
                  
                </div>
                </Link>
              </div>
              <div className="col-12 col-md-6 col-lg-2" onClick={onToggle}>
              <Link to="/requests/leave-approvals-list" className="text-decoration-none">
                <div className="p-2 border  dashboard-card-wrapper request-approvals">
                  <div className="fs-6 fw-semibold d-flex flex-column align-items-center">
                    Leave Approvals
                    <FaFileCircleCheck  className="dashboard-card-icon" />
                  </div>
                </div>
                </Link>
              </div>
              {/* <div className="col-12 col-md-6 col-lg-2" onClick={onToggle}>
              <Link to="/payroll" className="text-decoration-none">
              <div className="p-2 border  dashboard-card-wrapper payroll">
                  <div className="fs-6 fw-semibold d-flex flex-column align-items-center">
                    Payroll
                    <GiReceiveMoney  className="dashboard-card-icon" />
                  </div>
                </div>
                </Link>
              </div>
              <div className="col-12 col-md-6 col-lg-2" onClick={onToggle}>
              <Link to="#" className="text-decoration-none">
                <div className="border  dashboard-card-wrapper ffs">
                  <div className="fs-6 fw-semibold d-flex flex-column align-items-center">
                    Full & Final Settlement
                    <FaPlaneCircleExclamation className="dashboard-card-icon" />
                  </div>
                </div>
                </Link>
              </div> */}
            </div>
            </div>
  
        </div> }
    
       
      
    </>
    
  )
}

export default ApprovalDashboard;
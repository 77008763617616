import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import { Menu } from "../../../header/Header";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import Loader from "../../../Loader";

import { FaEye } from "react-icons/fa6";

import "./RecruitmentMasterList.css";

const RecruitmentMasterList = () => {
  const api_url = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();
  const userData = JSON.parse(sessionStorage.getItem("CurrentUserData"));
  
  
  const token = localStorage.getItem("token");

  if (!token) {
    navigate("/");
  }
  if ((userData.AtlasEmpID !== "AEC002") && (userData.AtlasEmpID !== "AEC057") && (userData.AtlasEmpID !== "AEC052")) {
    navigate("/user/userpunch");
  }
  const [recruitmentList, setRecruitmentList] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [searchText, setSearchText] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(true);
  // const [listtype, setListType] = useState("active");

  const [data, setDataArray] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  const recordsPerPage = 50;
  const lastIndex = currentPage * recordsPerPage;
  const firstIndex = lastIndex - recordsPerPage;
  const records = data.slice(firstIndex, lastIndex);
  const npage = Math.ceil(data.length / recordsPerPage);
  const numbers = [...Array(npage).keys()].map((num) => num + 1);

  const getDepartments = async () => {
    await axios
      .get(`${api_url}/emp/GetDepartments`)
      .then((res) => {
        setDepartments(res.data.results);
      })
      .catch((error) => {
        console.error("There was an error fetching the departments!", error);
      });
  };
  const handleSearchDropdownChange = (e) => {
    let val = e.target.value;
    setSelectedDepartment(val);
    FilterEmployee(searchText, val, recruitmentList);
  };
  const handleSearchInput = (e) => {
    let val = e.target.value;
    setSearchText(val);
    if (selectedDepartment !== "") {
      FilterEmployee(val, selectedDepartment, filteredData);
    } else {
      FilterEmployee(val, selectedDepartment, recruitmentList);
    }
  };
  const FilterEmployee = (search, dept, list) => {
    let filteredList = list;

    // Check if search text is not empty
    if (search.trim() !== "") {
      filteredList = filteredList.filter((item) => {
        // Ensure item.FirstName and item.LastName are not null or undefined
        const name = `${item.FirstName || ""} ${
          item.LastName || ""
        }`.toLowerCase();
        const keys = [
          name,
          item.AtlasEmpID || "",
          item.Department || "",
          item.Designation || "",
          item.ReportingManager || "",
          item.OfficialMobileNumber || "",
          item.OfficialEmailID || "",
        ];

        return keys.some((key) =>
          key.toLowerCase().includes(search.trim().toLowerCase())
        );
      });
    }

    // Check if department is not empty
    if (dept !== "") {
      filteredList = filteredList.filter((item) => {
        const department = item.Department || "";
        return department.toLowerCase() === dept.toLowerCase();
      });
    }

    // Apply both search and department filters
    if (search.trim() !== "" && dept !== "") {
      filteredList = filteredList.filter((item) => {
        const name = `${item.FirstName || ""} ${
          item.LastName || ""
        }`.toLowerCase();
        const keys = [
          name,
          item.AtlasEmpID || "",
          item.Department || "",
          item.Designation || "",
          item.ReportingManager || "",
          item.OfficialMobileNumber || "",
          item.OfficialEmailID || "",
        ];

        return keys.some((key) =>
          key.toLowerCase().includes(search.trim().toLowerCase())
        );
      });
    }

    setFilteredData(filteredList);
  };

  const labels = [
    "S.No",
    "Employee Name",
    "Department",
    "Designation",
    "Reporting to",
    "Mobile Number",
    "",
  ];

  useEffect(() => {
    setDataArray(filteredData);
    setCurrentPage(1);
  }, [filteredData]);

  const nextPage = () => {
    if (currentPage < npage) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const changeCpage = (id) => {
    setCurrentPage(id);
  };

  useEffect(() => {
    const fetchData = async () => {
      // setLoading(true); // Start loading
      try {
        // const payload = { ops: listtype };
        const res = await axios.get(`${api_url}/recruitment/recruitment-master-list`);
        // const res = await axios.post(`${api_url}/recruitment/recruitment-master-list`, payload, {
        //   headers: {
        //     "Content-Type": "application/json",
        //   },
        // });
        const emplist = res.data.list;
        setRecruitmentList(emplist);
        setFilteredData(emplist);
        await getDepartments();
      } catch (error) {
        console.log(error);
        setLoading(true);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className="container-fluid">
          <div className="button-wrapper">
            <div>
              <Menu link="/hrdash" />
            </div>
            <div>
              <Link to="/hr/recruitment-master/stepper" state={{ mode: "add" }}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  endIcon={<AddIcon />}
                >
                  New Recruitment
                </Button>
              </Link>
            </div>
          </div>
          {/* </div> */}
          <div className="table-view-container-wrapper">
            <div className="p-2 d-flex fw-bold shadow justify-content-center blockquote">
              Recruitments List - ATLAS
            </div>
            <div className="employee-search-bar-wrapper d-flex justify-content-start mb-4 ">
              <div className="d-flex justify-content-between w-100">
              <div className="d-flex">
                <div className="search-dept me-3">
                  <select
                    name="department"
                    className="form-input-select"
                    id="department"
                    aria-label="Small select example"
                    onChange={(e) => {
                      handleSearchDropdownChange(e);
                    }}
                  >
                    <option value="" defaultValue={""}>
                      Search All
                    </option>
                    {departments.map((dept) => (
                      <option key={dept.DeptID} value={dept.DepartmentName}>
                        {dept.DepartmentName}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="search-field">
                  <input
                    type="text"
                    className="form-input-text"
                    placeholder="Search Recruitment List"
                    onChange={(e) => {
                      handleSearchInput(e);
                    }}
                  />
                </div>
              </div>

              {/* <div>
                <select
                  style={{ width: `150px` }}
                  name="activestatus"
                  className="form-input-select "
                  aria-label=".form-select-sm example"
                  onChange={(e) => setListType(e.target.value)}
                  value={listtype || ""}
                  required
                >
                  <option value="inactive">In Active</option>
                  <option value="active">Active</option>
                </select>
              </div> */}
              </div>
            </div>
            <table className="table table-hover table-responsive">
              <thead>
                <tr>
                  {labels.map((item, index) => (
                    <th
                      scope="col"
                      key={index}
                      className="table-header-font-size"
                    >
                      {item}{" "}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="table-body-font-size">
                {records.map((item, index) => {
                  const sno = firstIndex + index + 1;
                  return (
                    <tr
                      className="py-0"
                      key={index}
                      onClick={() => {
                        sessionStorage.setItem("RecruitmentID", item.RecruitmentID)
                        navigate("/hr/recruitment-master/stepper", {
                          state: { mode: "edit", recid: item.RecruitmentID },
                          
                        });
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      <td className="py-0">{sno}</td>
                      {/* <td className="py-0">{emp.AtlasEmpID}</td> */}
                      <td className="py-0">
                        {item.FirstName + "  " + item.LastName}
                      </td>
                      <td className="py-0">{item.Department}</td>
                      <td className="py-0">{item.Designation}</td>
                      <td className="py-0">{item.ReportingManager}</td>
                      <td className="py-0">{item.OfficialMobileNumber}</td>
                      <td className="py-0">
                        <Link
                          to="/hr/recruitment-master/stepper"
                          state={{ mode: "edit", recid: item.RecruitmentID }}
                          onClick={() => {
                            sessionStorage.setItem("RecruitmentID", item.RecruitmentID);
                          }}
                        >
                          <FaEye />
                        </Link>
                      </td>
                      {/* <td className="py-0">
                        <Link
                          to="/hr/recruitment-master-form"
                          state={{ mode: "edit", reqid: item.RecruitmentID }}
                        >
                          <FaEye />
                        </Link>
                      </td> */}
                    </tr>
                  );
                })}
              </tbody>
            </table>

            <nav
              aria-label="Page navigation example"
              className="table-responsive mb-2"
            >
              <ul className="pagination justify-content-center">
                <li
                  className={`page-item ${currentPage === 1 ? "disabled" : ""}`}
                >
                  <a
                    className="page-link"
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      if (currentPage > 1) prevPage();
                    }}
                  >
                    Previous
                  </a>
                </li>
                {numbers.map((n) => (
                  <li
                    className={`page-item ${currentPage === n ? "active" : ""}`}
                    key={n}
                  >
                    <a
                      className="page-link"
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        changeCpage(n);
                      }}
                    >
                      {n}
                    </a>
                  </li>
                ))}
                <li
                  className={`page-item ${
                    currentPage === npage ? "disabled" : ""
                  }`}
                >
                  <a
                    className="page-link"
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      if (currentPage < npage) nextPage();
                    }}
                  >
                    Next
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      )}
    </>
  );
};

export default RecruitmentMasterList;

import React, { useState } from 'react';

const TableForm = () => {
  const [dateStrings, setDateStrings] = useState([]);
  const [timesheetData, setTimesheetData] = useState([
    { id: 1, date: '2024-12-01', hoursWorked: 8, task: 'Development' },
    { id: 2, date: '2024-12-02', hoursWorked: 7, task: 'Testing' },
    { id: 3, date: '2024-12-03', hoursWorked: 6, task: 'Documentation' },
  ]);

  const handleDates = (startdate, enddate) => {
    if (!startdate || !enddate) {
      alert("Start date and End date should not be null");
      return;
    }

    const startDate = new Date(startdate);
    const endDate = new Date(enddate);

    let generatedDates = [];

    let currentDate = new Date(startDate);
    while (currentDate <= endDate) {
      const dayOfWeek = currentDate.getDay();
      const formattedDate = currentDate.toLocaleDateString("en-GB");
      const label = dayOfWeek === 5 || dayOfWeek === 6 ? "WH" : "NW";

      generatedDates.push(`${formattedDate}, ${label}`);
      currentDate.setDate(currentDate.getDate() + 1);
    }

    setDateStrings(generatedDates);
  };

  const handleAddRow = () => {
    const newRow = {
      id: timesheetData.length + 1,
      date: '2024-12-04',
      hoursWorked: 8,
      task: 'Meeting',
    };
    setTimesheetData([...timesheetData, newRow]);
  };

  const handleDeleteRow = (id) => {
    setTimesheetData(timesheetData.filter(item => item.id !== id));
  };

  return (
    <div className="container mt-5">
      <h2>Timesheet</h2>

      {/* Table to display timesheet data */}
     <div className="container">
      <div className="row">
        <div className="col-1"></div>
      </div>
     </div>


    </div>
  );
};

export default TableForm;

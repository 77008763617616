export const nationalities = [
    "Omani",
    "Bangladeshi",
    "Egyptian",
    "Filipino",
    "Indian",
    "Irani",
    "Iraqi",
    "Pakistani",
    "Syrian",
    "Sudanese",
    "Srilankan",
    "Turkish",
  ];
  
  